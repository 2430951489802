import * as React from 'react'
import loadable from '@loadable/component'

// THIS IS A TEST PAGE

const FormPage = loadable(() => import('/src/templates/FormPage'))

const Page = ({ postInfo }) => {
    const post = {
        title: 'CTQ Automated Testing',
        indexStatus: 'noindex',
        followStatus: 'nofollow'
    }

    return <FormPage postInfo={postInfo} post={post} leadSource={'FALSE_LEAD'} />
}

export default Page
